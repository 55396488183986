













































































































/* eslint-disable */
import { Vue, Component } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import { UserModule } from "@/store/user";
import { OtherModule } from "@/store/other";
import StorageDb from "@/util/storage";
import { Encryption } from "@/util/encryption";
@Component
export default class extends Vue {
  validatePass_8(rules: any, value: any, callback: any) {
    const reg =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    if (reg.test(value)) {
      callback();
    } else {
      callback(new Error("영문, 숫자, 특수문자(! 또는 @) 포함 8자 이상 입력"));
    }
  }
  rules = {
    username: [{ required: true, message: "아이디를 작성해 주세요." }],
    password: [{ required: true, validator: this.validatePass_8 }],
  };
  info = {
    username: "",
    password: "",
    check: true,
  };

  btn_loadding = {
    submit: false,
  };

  async submit() {
    await (this.$refs["form"] as ElForm).validate();
    this.btn_loadding.submit = true;
    const { check, ...info } = this.info;
    if (check) {
      StorageDb.setLocal("login_info", info);
    } else {
      StorageDb.removeLocal("login_info");
    }
    await UserModule.login(info).finally(() => {
      this.btn_loadding.submit = false;
    });
    this.$message.success("로그인 환영합니다.");
    setTimeout(() => {
      if (this.$route.query.last === undefined) {
        this.$router.push("/");
      } else {
        const last: string = Encryption.base_dec(
          this.$route.query.last as string
        );
        this.$router.push(last);
      }
    }, 1000);
  }

  created() {
    const info = StorageDb.getLocal("login_info");
    if (info !== null) {
      this.info = { ...this.info, ...info };
    }
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
